@import 'styles/includes';

.rmdp-ep-arrow {
    top: 2px !important;

    &::after {
        box-shadow: none !important;
        border: 1px solid $colorBlue;
    }
    &[direction='bottom'] {
        margin-top: 0 !important;
        top: -2px !important;
    }
}

.Calendar,
.DatePicker {
    .rmdp-header {
        height: auto;
    }

    .rmdp-header-values {
        @extend %small;
        font-family: $fontBold;
        font-weight: 600;

        color: $colorBlue;
    }

    &.rmdp-wrapper {
        border: 1px solid $colorBlue;
        box-shadow: none;
    }

    .rmdp-week-day {
        font-family: $fontBold;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 16px;
        color: #6f6969;
    }

    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        background-color: $colorLichen;
        color: $colorContrast;
    }

    .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: $colorVanilla;
        border: 1px solid $colorYellow;
        color: $colorBlue;
        box-shadow: none;

        &:hover {
            background-color: $colorVanilla;
        }
    }

    .rmdp-day {
        width: 38px;
        height: 33px;
        font-family: $fontBold;
        font-weight: 600;
        color: $colorBlue;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            width: 33px;
            height: 33px;
            font-size: 1.2rem;
            position: relative;
            inset: 0;
        }

        &.rmdp-disabled {
            color: #c4c4c4;
        }

        &.rmdp-today {
            span {
                color: $colorBlue;
                background-color: $colorLichen;
            }
        }
    }

    .rmdp-arrow-container:hover {
        background-color: $colorBlue;
        box-shadow: none;
    }

    .rmdp-arrow {
        border: solid $colorBlue;
        border-width: 0 2px 2px 0;
        margin-top: 7px;
    }

    .rmdp-month-picker {
        .rmdp-day {
            width: auto;
            height: auto;

            span {
                padding: 4px;
                width: auto;
                height: auto;
            }
        }
    }
}

.Calendar {
    &.rmdp-wrapper {
        border: 0;
    }
}
