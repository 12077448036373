@import 'styles/includes';

html,
body {
    padding: 0;
    margin: 0;
    font-family: $fontBody;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

a {
    color: inherit;
    text-decoration: none;
}

.sr-only {
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    white-space: nowrap;
    text-transform: none;
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    -webkit-clip-path: inset(50%);
}

.no-scrolling {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

strong {
    font-weight: bold;
}
