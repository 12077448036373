@font-face {
    font-family: 'MuseoSans Regular';
    src:
        url('/fonts/MuseoSans-500.woff2') format('woff2'),
        url('/fonts/MuseoSans-500.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans Bold';
    src:
        url('/fonts/MuseoSans-700.woff2') format('woff2'),
        url('/fonts/MuseoSans-700.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans Black';
    src:
        url('/fonts/MuseoSans-900.woff2') format('woff2'),
        url('/fonts/MuseoSans-900.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('/fonts/Inter-400.woff2') format('woff2'),
        url('/fonts/Inter-400.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('/fonts/Inter-600.woff2') format('woff2'),
        url('/fonts/Inter-600.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}
