@import 'includes';

form {
    *[data-layout='true'] {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 115.2rem !important;
    }

    .marketing-field {
        input[type='checkbox'] {
            margin-top: 5px !important;
        }
    }
}

.lp-form-field {
    position: relative;

    &:has(span.lp-required) {
        label::after {
            content: '*';
            color: $colorError;
            position: relative;
        }
    }
}

.lp-required {
    display: none !important;
}

[data-editorblocktype='Field-checkbox'] {
    span,
    .lp-ellipsis {
        display: inline-block;
        left: unset;
        font-family: $fontBody;
        font-size: 1.4rem !important;
        font-weight: 400 !important;
        line-height: 2.4rem !important;
        color: inherit !important;
        position: relative !important;

        @include media(m) {
            font-size: 1.6rem !important;
            line-height: 2.6rem !important;
        }
    }

    .lp-form-field {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}
